import React from 'react';

import SelectSingleFilter from './SelectSingleFilter/SelectSingleFilter';
import SelectMultipleFilter from './SelectMultipleFilter/SelectMultipleFilter';
import KeywordFilter from './KeywordFilter/KeywordFilter';
import PriceFilter from './PriceFilter/PriceFilter';
import DropDownFilter from './DropDownFilter/DropDownFilter';
import RangeFilter from './RangeFilter/RangeFilter';
import MultiLevelFilter from './MultiLevelFilter/MultiLevelFilter';
import { useRootContext } from '../../contexts/RootContext';
import { getDefaultCountry } from '../../api/locale';

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
function FilterComponent(props) {
  const { idPrefix, filterConfig, urlQueryParams, initialValues, getHandleChangedValueFn, intl, ...rest } = props;
  const {
    id,
    type,
    queryParamNames,
    label,
    isOpen,
    config,
    translatableLabels,
    translationGroup,
    placeholderKey,
  } = filterConfig;
  const { liveEdit, showAsPopup } = rest;

  const { user } = useRootContext()
  const filterLabel = intl.formatMessage({ id: `config.labels.${id}` });

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${id.toLowerCase()}`;
  const name = id;

  switch (type) {
    case 'SelectSingleFilter': {
      return (
        <SelectSingleFilter
          id={componentId}
          label={filterLabel}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'SelectMultipleFilter': {
      return (
        <SelectMultipleFilter
          id={componentId}
          label={filterLabel}
          isOpen={isOpen}
          name={name}
          translationGroup={translationGroup}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'PriceFilter': {
      return (
        <PriceFilter
          id={componentId}
          label={filterLabel}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          countryCode={user?.countryCode || getDefaultCountry(undefined)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'KeywordFilter':
      return (
        <KeywordFilter
          id={componentId}
          label={filterLabel}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    case 'DropDownFilter': {
      return (
        <DropDownFilter
          id={componentId}
          label={filterLabel}
          isOpen={isOpen}
          name={name}
          queryParamNames={queryParamNames}
          translationGroup={translationGroup}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          initialValues={initialValues(queryParamNames, liveEdit)}
          translatableLabels={translatableLabels}
          {...config}
          {...rest}
        />
      );
    }
    case 'RangeFilter': {
      return (
        <RangeFilter
          id={componentId}
          label={filterLabel}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    case 'MultiLevelFilter': {
      return (
        <MultiLevelFilter
          id={componentId}
          name={name}
          label={filterLabel}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />
      );
    }
    default:
      return null;
  }
}

export default FilterComponent;
